import React, { useEffect, useState } from "react";
import styles from "../styles/pages/Openings.module.css";
import { FaSuitcase, FaRegCalendarAlt } from "react-icons/fa";
import { FiSearch, FiMapPin } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { careersData } from "../utils/constants";

const jobsData = careersData.map(job => ({
  ...job,
  status: job.applicants >= job.slots ? "Closed" : "Open",
  progress: Math.min((job.applicants / job.slots) * 100, 100),
}));

const getProgressColor = (progress) => {
  if (progress >= 80) return "#E25A5A";
  if (progress >= 50) return "#f4a261";
  return "#2a9d8f";
};

const Openings = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobs, setFilteredJobs] = useState(jobsData);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({ type: "", mode: "", duration: "" });
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    filterJobs(term, filters);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleApplyFilters = () => {
    filterJobs(searchTerm, filters);
    setIsFilterOpen(false);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const filterJobs = (search, filterValues) => {
    let filtered = jobsData.filter(job =>
      job.title.toLowerCase().includes(search.toLowerCase())
    );

    if (filterValues.type) filtered = filtered.filter(job => job.details.type === filterValues.type);
    if (filterValues.mode) filtered = filtered.filter(job => job.details.mode === filterValues.mode);
    if (filterValues.duration) filtered = filtered.filter(job => job.details.duration === filterValues.duration);

    setFilteredJobs(filtered);
  };

  const handleApply = (job) => {
    console.log(job);
    navigate(`/careers/openings/job-details/${job.id}`, { state: { job } });
  };



  return (
    <div className={styles.careersContainer}>
      <header className={styles.header}>
        <h2>Current Openings</h2>
      </header>

      <div className={styles.searchFilter}>
        <div className={styles.searchBarContainer}>
          <FiSearch className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search"
            className={styles.searchBar}
            value={searchTerm}
            onChange={handleSearch}
          />
          <button className={styles.filterButton} onClick={toggleFilter}>
            <img src="/assets/images/filter_icon.svg" alt="Filter" />
          </button>
        </div>
      </div>

      {isFilterOpen && (
        <div className={styles.filterModal}>
          <h3>Filter Jobs</h3>

          <div className={styles.filterOptions}>
            <div className={styles.optionWrapper}>
              <label htmlFor="jobType">Job Type:</label>
              <select id="jobType" name="type" value={filters.type} onChange={handleFilterChange}>
                <option value="">All</option>
                <option value="Full Time">Full Time</option>
                <option value="Part Time">Part Time</option>
              </select>
            </div>
            <div className={styles.optionWrapper}>
              <label htmlFor="workMode">Work Mode:</label>
              <select id="workMode" name="mode" value={filters.mode} onChange={handleFilterChange}>
                <option value="">All</option>
                <option value="Remote">Remote</option>
                <option value="Hybrid">Hybrid</option>
                <option value="On-Site">On-Site</option>
              </select>
            </div>
            <div className={styles.optionWrapper}>
              <label htmlFor="duration">Duration:</label>
              <select id="duration" name="duration" value={filters.duration} onChange={handleFilterChange}>
                <option value="">All</option>
                <option value="3 Months">3 Months</option>
                <option value="3-6 Months">3-6 Months</option>
                <option value="More than 6 Months">More than 6 Months</option>
              </select>
            </div>
          </div>
          <button className={styles.applyFiltersButton} onClick={handleApplyFilters}>
            Apply Filters
          </button>
        </div>

      )}

      <div className={styles.jobGrid}>
        {filteredJobs.map((job, index) => (
          <div key={index} className={styles.jobCard}>
            <div className={styles.shareIcon}>
              <IoShareSocialOutline />
            </div>
            <div className={styles.jobCardHeader}>
              <h3 className={styles.jobTitle}>{job.title}</h3>
            </div>
            <div className={styles.jobDetails}>
              <span><FaSuitcase /> {job.details.type}</span>
              <span><FiMapPin /> {job.details.mode}</span>
              <span><FaRegCalendarAlt /> {job.details.duration}</span>
            </div>
            <div className={styles.roleLabel}>Role:</div>
            <p className={styles.roleDescription}>
              {job.shortDescription}
            </p>
            <div className={styles.progressContainer}>
              <div className={styles.progressBar}>
                <div
                  className={styles.progressFill}
                  style={{
                    width: `${job.progress}%`,
                    backgroundColor: getProgressColor(job.progress),
                  }}
                />
              </div>
              <span style={{ color: getProgressColor(job.progress), fontSize: 12 }}>
                {job.progress >= 80 ? "Closes Soon" : job.progress >= 50 ? "Filling Fast" : "Open"}
              </span>
            </div>
            <button
              className={styles.applyButton}
              onClick={() => handleApply(job)}
              disabled={job.status === "Closed"}
            >
              {job.status === "Closed" ? "Closed" : "Apply"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Openings;
