import React from 'react';
import styles from '../../styles/components/careers/InternshipAtJoms.module.css';
import { useNavigate } from 'react-router-dom';

const InternshipAtJoms = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
          <h2>
            Internships at <span className={styles.highlight}>JOMS</span>
          </h2>
      <div className={styles.content}>
        <div className={styles.text}>
          <p>
            Embark on a learning journey with JOMS, where you will work on
            groundbreaking projects that create real-world impact while
            collaborating with people in a dynamic, growth-driven environment.
            This is your chance to turn passion into purpose, gain hands-on
            experience with cutting-edge technologies, and build a strong
            foundation for a remarkable career.
          </p>
          <button className={styles.learnMoreButton} onClick={()=> navigate('/careers/internship-at-joms')}>Learn More</button>
        </div>
        <div className={styles.image}>
          <img
            src="/assets/images/careers/internship.jpeg"
            alt="Internships at JOMS"
            className={styles.img}
          />
        </div>
      </div>
    </section>
  );
};

export default InternshipAtJoms;
