import { FaEye, FaFlag, FaHeart, FaStar, FaLightbulb, FaSeedling, FaHandshake } from 'react-icons/fa';
import { FiGlobe } from "react-icons/fi";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL;
// export const PLAY_STORE_URL = process.env.REACT_APP_PLAY_STORE_URL;

console.log("API_BASE_URL:", API_BASE_URL);
export const navLinks = [
  { id: '/careers', title: 'Careers' },
  { id: '#ourproducts', title: 'Explore Products' },
  { id: '#contactus', title: 'Contact Us' },
];

export const headerTagline = 'Innovating for Global Impact'
export const headerTextline = 'Transforming Lives Through Meaningful Consumer Experience'

export const aboutUs = [
  {
    title: 'Vision',
    img: '/assets/images/JOMS_Vision.png',
    icon: <FaEye />,
    description: 'Drive global change by crafting innovative products and services that elevate everyday living. We aim to blend technology and creativity to empower individuals and communities for a sustainable future.',
    details: '',
    action: 'Learn More',
  },
  {
    title: 'Mission',
    img: '/assets/images/JOMS_Mission.png',
    icon: <FaFlag />,
    description: 'Transform lives by delivering exceptional experiences through passion, creativity, and technology. We strive to create inclusive solutions that inspire innovation and leave a lasting positive impact on society.',
    details: '',
    action: 'Explore Now',
  },
  {
    title: 'Values',
    img: '/assets/images/JOMS_Values.png',
    icon: <FaHeart />,
    description: 'Integrity, Courage, Commitment, Innovation, and Social Responsibility. These principles guide our actions, inspire our culture, and drive meaningful change for the greater good.',
    details: '',
    action: 'View Gifts',
  },
];

export const ourStory = [
  {
    title: 'Our Story',
    description: 'JOMS was born out of the shared vision of passionate individuals who believe in the power of innovation to make a difference. Our journey started with a mission to craft products that go beyond meeting customer needs, revolutionizing how they engage with the world.',
  },
];

export const ourImpact = [
  {
    icon: <FaStar />,
    title: "Empowering Lives",
    description: "By developing solutions that solve real-life challenges.",
  },
  {
    icon: <FaLightbulb />,
    title: "Driving Innovation",
    description: "Through research and technology integration.",
  },
  {
    icon: <FaSeedling />,
    title: "Sustainability",
    description: "Designing eco-friendly and socially responsible products.",
  },
  {
    icon: <FaHandshake />,
    title: "Community Engagement",
    description: "Partnering with organisations to give back to society.",
  },
];

export const ourTeam = [
  {
    title: 'Our Team',
    description: 'A diverse and dynamic group of professionals with expertise in technology, design, marketing, and operations. United by a shared passion for creating impactful products.',
  },
];

export const ourProductsAndServices = [
  {
    title: 'Consumer Products',
    description: 'Innovative solutions across categories that enhance convenience, wellness, and lifestyle. We are soon launching "Let\'s Sync Up," a product that is forever going to change the dating experience.',
  },
  {
    title: 'Fashion',
    description: 'Tailored offerings that cater to customer needs and enrich experiences. A fresh new high-quality thread is being spun to create a sought-after clothing brand, launching in the summer of 2025.',
  },
  {
    title: 'Technology Integration',
    description: 'Leveraging advanced technologies to create intuitive and user-friendly solutions. We are working on a user-generated content platform that helps brands understand product users\' sentiments.',
  },
];

export const whyChooseUs = [
  { title: 'Customer-Centric Design', description: 'We prioritize design to ensure all our products are a true reflection of our customer’s desires.' },
  { title: 'Innovative Solutions', description: 'We love to swim against the tide to ensure we stay ahead of market trends.' },
  { title: 'Social Impact', description: 'We stay committed to positively impacting communities and the environment.' },
  { title: 'Team Excellence', description: 'We are a team that is courageous, dedicated, passionate, and committed to delivering high-quality work through innovative products.' },
];

export const ourVision = [
  {
    icon: <FiGlobe />,
    title: "Global Expansion",
    text: [
      "Establishing a presence in emerging markets.",
      "Building partnerships with international distributors.",
      "Localizing services to meet cultural and regional needs."
    ],
  },
  {
    icon: <FaHandshake />,
    title: "Partnerships",
    text: [
      "Collaborating with non-profits for community programs.",
      "Forming strategic alliances to boost innovation.",
      "Engaging with industry leaders for knowledge sharing."
    ],
  },
  {
    icon: <FaLightbulb />,
    title: "Innovation",
    text: [
      "Adopting AI and machine learning for enhanced user experience.",
      "Expanding R&D teams for cutting-edge developments.",
      "Launching sustainable and eco-friendly solutions."
    ],
  },
];

export const joinUs = [
  {
    title: 'Careers',
    description: 'Exciting opportunities for passionate individuals to join our team. We believe a workplace should feel like your second home, where you feel comfortable and safe. We often explore new trends and places together that propel us to be better and do more.',
  },
  {
    title: 'Partnerships',
    description: 'Collaborate with us to co-create impactful products and solutions. Together let\'s build something meaningful that makes us proud and gives us all a sense of accomplishment.',
  },
  {
    title: 'Investors',
    description: 'Be part of a transformative journey with JOMS and always stay ahead of the curve. It\'s not just about the money, it\'s also about the value you bring or create with us that really matters.',
  },
];

export const contactUs = {
  address: '#592/2/635, NGEF Layout, Nagarbhavi, Bangalore North, Bangalore-560072.',
  email: 'info@letsyncup.com',
};

export const ctaButtons = [
  { title: 'Discover Our Products', description: 'Explore how JOMS can enrich your life.' },
  { title: 'Join the Movement', description: 'Be part of a community making a difference.' },
  { title: 'Partner with Us', description: 'Collaborate for a greater impact.' },
  { title: 'Stay Connected', description: 'Sign up for updates, newsletters, and exclusive offers.' },
];

export const careersData = [
  {
    id: 'dg1',
    title: 'User Interface Designer - Internship',
    applicants: 0, 
    slots: 50, 
    details: {
      tags: ['Internship', 'Hybrid', 'Bangalore'],
      jobType: 'Internship',
      type: "Full Time", 
      mode: 'Hybrid',
      duration: "3-6 Months",
      salary: 'UnPaid',
      url: 'https://www.linkedin.com/jobs/view/4105650387'
    },
    shortDescription: "Collaborate with design and development teams to create user-friendly interfaces for web and mobile apps, gaining hands-on UI/UX experience on impactful projects.",
    description: {
      overview: {
        jobTitle: 'User Interface (UI) Designer Intern',
        location: 'Bangalore, India (Remote/Hybrid options available)',
        employmentType: 'Internship | Unpaid (Potential for stipend based on performance)',
        duration: '3–6 Months (with opportunity for extension or full-time role)'
      },
      aboutUs: `JOMS (Just One More Step) Commerce and Technologies Private Limited, formed with a vision to create significant social impact by creating most relevant and innovative consumer products and services. We are a team of passionate individuals who are united by the courage and commitment to create real impact by building products that is helpful for customers and enriches their experiences across different aspects of life.`,
      roleOverview: `As a UI Designer Intern, you will collaborate with our design and development teams to create intuitive, visually appealing, and user-friendly interfaces for our web and mobile applications. This role offers an excellent opportunity to gain hands-on experience in UI/UX design while contributing to impactful projects.`,
      keyResponsibilities: [
        'Design wireframes, prototypes, and high-fidelity mockups for web and mobile applications.',
        'Collaborate with product managers and developers to translate user needs into elegant and functional interfaces.',
        'Maintain and evolve the design system to ensure consistency across platforms.',
        'Conduct user research and usability testing to refine design solutions.',
        'Stay updated on the latest UI/UX design trends, tools, and technologies.',
        'Provide input on visual branding elements, including icons, typography, and color schemes.',
      ],
      qualifications: [
        'Education: Enrolled in or recently graduated from a degree program in Design, Human-Computer Interaction, or a related field.',
        'Proficiency in design tools like Figma, Sketch, Adobe XD, or similar software.',
        'Basic understanding of responsive design principles and usability guidelines.',
        'A strong portfolio showcasing UI design projects (academic or professional).',
        'Attention to detail, creativity, and a user-centered design approach.',
        'Excellent communication and collaboration skills.',
        'Knowledge of front-end development (HTML, CSS, JavaScript) is a plus.',
      ],
      perksAndBenefits: [
        'Certificate of Completion.',
        'Opportunity to work on real-world projects and enhance your portfolio.',
        'Potential to transition into a full-time role based on performance.'
      ],
      howToApply: `Please submit your resume and portfolio to `
    },
    footer: 'We are an equal-opportunity employer and encourage candidates from diverse backgrounds to apply.'
  },
  {
    id: 'dg2',
    title: 'Graphic Designer - Internship',
    applicants: 10, 
    slots: 50, 
    details: {
      tags: ['Internship', 'Hybrid', 'Bangalore'],
      jobType: 'Internship',
      type: "Full Time", 
      mode: 'Hybrid',
      duration: "3-6 Months",
      salary: 'UnPaid',
      url: 'https://www.linkedin.com/jobs/view/4108996041'
    },
    shortDescription: "Design visuals and videos to captivate audiences and elevate brand identity, working with marketing and content teams on digital assets and video editing.",
    description: {
      overview: {
        jobTitle: 'Graphic Designer Intern',
        location: 'Bangalore, India (Remote/Hybrid options available)',
        employmentType: 'Internship | Unpaid (Potential for stipend based on performance)',
        duration: '3–6 Months (with opportunity for extension or full-time role)'
      },
      aboutUs: `JOMS (Just One More Step) Commerce and Technologies Private Limited, formed with a vision to create significant social impact by creating most relevant and innovative consumer products and services. We are a team of passionate individuals who are united by the courage and commitment to create real impact by building products that is helpful for customers and enriches their experiences across different aspects of life.`,
      roleOverview: `As a Graphic Designer Intern, you will bring creative ideas to life through compelling visuals and engaging video content. You’ll work closely with the marketing and content teams to design digital assets, edit videos, and craft visuals that captivate audiences and elevate brand identity.`,
      keyResponsibilities: [
        'Create high-quality graphics, illustrations, and social media assets tailored to our brand.',
        'Edit and produce engaging video content for social media platforms, marketing campaigns, and product promotions.',
        'Collaborate with the marketing team to brainstorm creative concepts and ensure alignment with brand guidelines.',
        'Assist in developing animations, motion graphics, and visual effects.',
        'Optimise graphics and videos for web and social media platforms to ensure optimal performance.',
        'Stay updated on design trends, video editing techniques, and relevant tools.',
      ],
      qualifications: [
        'Education: Enrolled in or recently graduated from a degree/diploma program in Graphic Design, Animation, Visual Arts, or a related field.',
        'Proficiency in design tools such as Adobe Photoshop, Illustrator, and Canva.',
        'Hands-on experience with video editing tools like Adobe Premiere Pro, Final Cut Pro, or DaVinci Resolve is preferred.',
        'Knowledge of motion graphics tools like Adobe After Effects is a strong plus.',
        'Strong attention to detail and a creative mindset with a portfolio showcasing design and video editing projects.',
        'Basic understanding of social media platforms and how to design for them.',
        'Excellent time management skills and the ability to work on multiple projects simultaneously.'
      ],
      perksAndBenefits: [
        'Certificate of Completion.',
        'Opportunity to enhance your portfolio with real-world projects.',
        'Exposure to working in a fast-paced startup environment.',
        'Potential to transition into a full-time role based on performance.',
      ],
      howToApply: `Submit your resume and portfolio to `
    },
    footer: 'We are an equal-opportunity employer and welcome candidates from diverse backgrounds to apply.'
  },
  {
    id: 'fin1',
    title: 'Finance - Internship',
    applicants: 20, 
    slots: 50, 
    details: {
      tags: ['Internship', 'Hybrid', 'Bangalore'],
      jobType: 'Internship',
      type: "Full Time", 
      mode: 'Hybrid',
      duration: "3-6 Months",
      salary: 'UnPaid',
      url: 'https://www.linkedin.com/jobs/view/4108986533'
    },
    shortDescription: "Support financial planning, analysis, and reporting with the finance team, gaining hands-on experience in financial management in a fast-paced startup environment.",
    description: {
      overview: {
        jobTitle: 'Finance Intern',
        location: 'Bangalore, India (Remote/Hybrid options available)',
        employmentType: 'Internship | Unpaid (Potential for stipend based on performance)',
        duration: '3–6 Months (with opportunity for extension or full-time role)'
      },
      aboutUs: `JOMS (Just One More Step) Commerce and Technologies Private Limited, formed with a vision to create significant social impact by creating most relevant and innovative consumer products and services. We are a team of passionate individuals who are united by the courage and commitment to create real impact by building products that is helpful for customers and enriches their experiences across different aspects of life.`,
      roleOverview: `As a Finance Intern, you will work closely with the finance team to support financial planning, analysis, and reporting activities. This internship offers an excellent opportunity to develop a strong foundation in financial management while contributing to meaningful projects in a fast-paced startup environment.`,
      keyResponsibilities: [
        'Assist in preparing and analysing financial statements and reports.',
        'Support the budgeting and forecasting process by gathering and interpreting data.',
        'Conduct research and analysis on market trends and financial benchmarks.',
        'Collaborate with the finance team to manage accounts payable/receivable and track expenses.',
        'Help ensure compliance with financial regulations and company policies.',
        'Provide input on cash flow management and cost optimisation strategies.',
        'Contribute to ad hoc financial projects and presentations as required.',
      ],
      qualifications: [
        'Education: Enrolled in or recently graduated from a degree program in Finance, Accounting, Economics, or a related field.',
        'Strong analytical skills and attention to detail.',
        'Proficiency in MS Excel and familiarity with accounting software (e.g., QuickBooks, Tally) is a plus.',
        'Excellent verbal and written communication skills.',
        'Ability to manage multiple tasks and meet deadlines in a fast-paced environment.',
        'A proactive attitude and eagerness to learn about financial operations in a startup setting.',
      ],
      perksAndBenefits: [
        'Certificate of Completion.',
        'Hands-on experience with financial tools and practices.',
        'Opportunity to contribute to strategic financial decisions and learn about startup finance.',
        'Potential to transition into a full-time role based on performance.',
      ],
      howToApply: `Submit your resume and a brief cover letter highlighting your interest in the role and any relevant coursework or experience to `
    },
    footer: 'We are an equal-opportunity employer and welcome candidates from diverse backgrounds to apply.'
  },
  {
    id: 'dm1',
    title: 'Digital Marketing - Internship',
    applicants: 30, 
    slots: 50, 
    details: {
      tags: ['Internship', 'Hybrid', 'Bangalore'],
      jobType: 'Internship',
      type: "Full Time", 
      mode: 'Hybrid',
      duration: "3-6 Months",
      salary: 'UnPaid',
      url: 'https://www.linkedin.com/jobs/view/4102584632'
    },
    shortDescription: "Work with the marketing team to boost online visibility and engagement through digital campaigns, social media, content creation, and performance analytics.",
    description: {
      overview: {
        jobTitle: 'Digital Marketing Intern',
        location: 'Bangalore, India (Remote/Hybrid options available)',
        employmentType: 'Internship | Unpaid (Potential for stipend based on performance)',
        duration: '3 Months (with opportunity for extension or full-time role)'
      },
      aboutUs: `JOMS Commerce and Technologies Pvt. Ltd. is driven by a bold vision to create products and services that make a real difference in people’s lives. At JOMS, we are not just building businesses, we are crafting meaningful experiences that solve real-world problems and enrich everyday living. United by passion, courage, and commitment, our diverse team of innovators is dedicated to designing solutions that are both relevant and impactful. From cutting-edge consumer products to services that inspire positive change, we aim to deliver excellence while staying true to our values of innovation, customer focus, and social responsibility. At JOMS, every step we take is about making life better, one product, one service, and one idea at a time.`,
      roleOverview: `As a Digital Marketing Intern, you will work closely with our marketing team to drive online visibility, engagement, and growth across multiple channels. You will gain hands-on experience in digital campaigns, social media management, content creation, and performance analytics.`,
      keyResponsibilities: [
        'Assist in creating and managing digital marketing campaigns across platforms (Facebook, Instagram, Google Ads, LinkedIn).',
        'Develop and schedule engaging content for social media, blogs, and newsletters.',
        'Support SEO optimization efforts by researching relevant keywords and implementing strategies.',
        'Monitor and report on digital campaign performance metrics (traffic, engagement, conversions).',
        'Help plan and execute influencer marketing campaigns.',
        'Collaborate with cross-functional teams to align messaging and branding.',
        'Stay updated on digital marketing trends and emerging tools.'
      ],
      qualifications: [
        'Education: Enrolled in or recently graduated from a bachelor’s degree program in Marketing, Business, Communications, or a related field.',
        'Strong verbal and written communication skills.',
        'Basic understanding of social media platforms and digital advertising tools.',
        'Proficiency in MS Office Suite (Word, Excel, PowerPoint).',
        'Knowledge of tools like Canva, Google Analytics, or any marketing platforms (a plus).',
        'Ability to work independently, take initiative, and manage time effectively.',
        'A passion for marketing, content creation, and social media trends.'
      ],
      perksAndBenefits: [
        'Certificate of Completion.',
        'Opportunity to work on real-world projects and build a strong portfolio.',
        'Potential to transition into a full-time role based on performance.'
      ],
      howToApply: `Submit your resume and a brief cover letter highlighting your interest in the role and relevant experience to `
    },
    footer: 'We are an equal-opportunity employer and welcome candidates from diverse backgrounds to apply.'
  },
  {
    id: 'eng1',
    title: 'Flutter Developer - Internship',
    applicants: 40, 
    slots: 50, 
    details: {
      tags: ['Internship', 'Hybrid', 'Bangalore'],
      jobType: 'Internship',
      type: "Full Time", 
      mode: 'Hybrid',
      duration: "3-6 Months",
      salary: 'UnPaid',
      url: 'https://www.linkedin.com/jobs/view/4105650387'
    },
    shortDescription: "As a Flutter Developer Intern, you'll help design, develop, and maintain high-performance cross-platform mobile apps, collaborating with the tech team to ensure an exceptional user experience.",
    description: {
      overview: {
        jobTitle: 'Flutter Developer Intern',
        location: 'Bangalore, India (On-site/Hybrid)',
        employmentType: 'Internship | Unpaid',
        duration: '3 to 6 months as aligned with the company’s needs and adaptable to college or university internship guidelines.'
      },
      aboutUs: `JOMS, short for Just One More Step, combines creativity and technology to develop innovative products that elevate customer experiences. Our flagship projects include a range of cutting-edge products and solutions, such as e-commerce marketplace, premium fashion brands, curated luxury experiences, and much more`,
      roleOverview: `As a Flutter Developer Intern, you will contribute to designing, developing, and maintaining high-performance mobile applications. You’ll work closely with the tech team to build cross-platform apps for iOS and Android, ensuring an exceptional user experience and top-notch performance.`,
      keyResponsibilities: [
        'Develop and maintain cross-platform mobile applications using Flutter and Dart.',
        'Collaborate with UI/UX designers to translate wireframes and mockups into responsive, user-friendly interfaces.',
        'Integrate REST APIs, third-party libraries, and backend services.',
        'Optimize application performance for scalability and responsiveness.',
        'Debug and resolve technical issues, ensuring app stability and functionality.',
        'Participate in code reviews to maintain high coding standards and improve code quality.',
        'Stay updated with the latest Flutter features, tools, and best practices.'
      ],
      qualifications: [
        'Education: Enrolled in or recently graduated from a degree/diploma program in Computer Science, Information Technology, or a related field.',
        'Strong understanding of Flutter, Dart, and mobile app development lifecycle.',
        'Familiarity with state management tools such as Provider, Riverpod, or Bloc.',
        'Basic knowledge of integrating RESTful APIs and working with JSON data.',
        'Experience with version control systems like Git.',
        'Familiarity with IDEs such as Android Studio or Visual Studio Code is a plus.',
        'Understanding of Material Design principles and app UI/UX best practices.',
        'Knowledge of Firebase (Firestore, Authentication, Analytics) is a plus.',
        'Strong problem-solving skills, attention to detail, and a proactive attitude.'
      ],
      perksAndBenefits: [
        'Internship Experience Certificate for successfully completing the program as per policy.',
        'On-site, Hybrid, Remote working option to accommodate your academic commitments.',
        'Full-time role offer based on your performance and company’s requirements.',
        'Training and Workshops are held periodically for up-skilling.',
        'Join us at industry events, with all costs covered for you to gain valuable experience.',
        'Travel Reimbursement related to internship activities based on your role.',
        'Free Food and Beverages while working at the office, any time of the day.',
        'Leave Policy to cater to any health or life situations, upon approval.',
        'Public Holidays in accordance with company policy.',
      ],
      howToApply: `Submit your resume and a brief cover letter highlighting your interest in the role and relevant experience to hello@joms.in. We are an equal-opportunity employer and welcome candidates from diverse backgrounds to apply. `
    },
    footer: 'We are an equal-opportunity employer and encourage candidates from diverse backgrounds to apply.'
  },
];

