import React, { useEffect } from 'react'
import Hero from '../components/home/Hero'
import AboutUs from '../components/home/AboutUs'
import Story from '../components/home/Story'
import Products from '../components/home/Products'
import Contact from '../components/home/Contact'
import Newsletter from '../components/home/Newsletter'
import Footer from '../components/home/Footer'
import CoreValues from '../components/home/CoreValues'
import Team from '../components/home/Team'
import RoadMap from '../components/home/Roadmap'

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Hero />
            <AboutUs />
            <CoreValues />
            <Story />
            <Team />
            <Products />
            <RoadMap />
            <Newsletter />
            <Contact />
            <Footer />
        </>
    )
}

export default Home