import React, { useEffect, useState } from "react";
import styles from "../../styles/components/careers/CurrentOpenings.module.css";
import { FaSuitcase, FaRegCalendarAlt } from "react-icons/fa";
import { FiSearch, FiMapPin } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { careersData } from "../../utils/constants";

const jobsData = careersData.map(job => ({
    ...job,
    status: job.applicants >= job.slots ? "Closed" : "Open",
    progress: Math.min((job.applicants / job.slots) * 100, 100),
})).slice(0, 3);

const getProgressColor = (progress) => {
    if (progress >= 80) return "#E25A5A";
    if (progress >= 50) return "#f4a261";
    return "#2a9d8f";
};

const CurrentOpenings = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredJobs, setFilteredJobs] = useState(jobsData);    
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = jobsData.filter(job =>
            job.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredJobs(filtered);
    }, [searchTerm]);

    return (
        <div id="current-openings" className={styles.careersContainer}>
            <h2 className={styles.heading}>Current Openings</h2>

            <div className={styles.searchFilter}>
                <div className={styles.searchBarContainer}>
                    <FiSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search"
                        className={styles.searchBar}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.jobGrid}>
                {filteredJobs.length > 0 ? filteredJobs.map((job, index) => (
                    <div key={index} className={styles.jobCard}>
                        <div className={styles.shareIcon}>
                            <IoShareSocialOutline />
                        </div>
                        <div className={styles.jobCardHeader}>
                            <h3 className={styles.jobTitle}>{job.title}</h3>
                        </div>
                        <div className={styles.jobDetails}>
                            <span><FaSuitcase /> {job.details.type}</span>
                            <span><FiMapPin /> {job.details.mode}</span>
                            <span><FaRegCalendarAlt /> {job.details.duration}</span>
                        </div>
                        <div className={styles.roleLabel}>Role:</div>
                        <p className={styles.roleDescription}>
                            {job.shortDescription}
                        </p>
                        <div className={styles.progressContainer}>
                            <div className={styles.progressBar}>
                                <div
                                    className={styles.progressFill}
                                    style={{
                                        width: `${job.progress}%`,
                                        backgroundColor: getProgressColor(job.progress),
                                    }}
                                />
                            </div>
                            <span style={{ color: getProgressColor(job.progress) }}>
                                {job.progress >= 80 ? "Closes Soon" : job.progress >= 50 ? "Filling Fast" : "Open"}
                            </span>
                        </div>
                        <button
                            className={styles.applyButton}
                            onClick={()=> navigate(`/careers/openings/job-details/${job.id}`, { state: { job } })}
                            disabled={job.status === "Closed"}
                        >
                            {job.status === "Closed" ? "Closed" : "Apply"}
                        </button>
                    </div>
                )) : (
                    <p className={styles.noResults}>No jobs found</p>
                )}
            </div>
            <div className={styles.viewMoreContainer}>
                <button className={styles.viewMoreButton} onClick={() => navigate("/careers/openings")}>View More</button>
            </div>
        </div>
    );
};

export default CurrentOpenings;
