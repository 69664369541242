import React from "react";
import styles from "../../styles/components/home/Contact.module.css";
// import { FaLinkedin } from "react-icons/fa";

const Contact = () => {
    return (
        <div className={styles.contactContainer}>
            {/* Left Section - Contact Details */}
            <div className={styles.contactDetails}>
                <h2 className={styles.title}>Contact</h2>
                <p className={styles.email}>
                    <strong>Email:</strong> hello@joms.in
                </p>
                <p className={styles.address}>
                    <strong>Office Address:</strong><br />
                    #592/2/635, NGEF Layout, Nagarbhavi,
                    Bangalore North, Bangalore-560072.
                </p>
                {/* <a
                    href="https://www.linkedin.com/company/joms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.linkedinIcon}
                >
                    <FaLinkedin size={30} />
                </a> */}
            </div>

            {/* Divider Line */}
            <div className={styles.divider}></div>

            {/* Right Section - Google Map */}
            <div className={styles.mapSection}>
                <h2 className={styles.title}>Headquarters</h2>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7776.196199419724!2d77.50436224526194!3d12.965574149396863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f2d91e1d51f%3A0xca0452d5b7a2e01b!2sExcel%20Coworks%20Nagarabhavi!5e0!3m2!1sen!2sin!4v1734308809383!5m2!1sen!2sin"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="address"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;
