import React from 'react'
import styles from "../styles/pages/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>JOMS Commerce & Technologies Private Limited – Privacy Policy</h1>
        <p>Updated and Effective January 1, 2025</p>
      </header>

      <section className={styles.section}>
        <h2>WELCOME</h2>
        <p>
          JOMS is a membership-based online community for dating, networking, and discovery. Our goal is to foster a safe, trusted community for members to meet, share, and engage in ways that will result in quality real-world connections and experiences.
        </p>
        <ul>
          <li>
            <strong>Privacy Protection:</strong> Your information, interest, and involvement remain strictly between you and us.
          </li>
          <li>
            <strong>Community Standards:</strong> Only vetted community members adhering to our code of conduct are selected.
          </li>
          <li>
            <strong>Feature Control:</strong> Use the "hide" feature, "paused" status, and easily toggle "social mode" and location services.
          </li>
        </ul>
        <p>
          Have any questions? Contact us at{' '}
          <a href="mailto:hello@joms.in" className={styles.link}>
            hello@joms.in
          </a>.
        </p>
      </section>

      <section className={styles.section}>
        <h2>INTRODUCTION</h2>
        <p>
          By submitting an application, creating a Raya account, or using our services, you agree to the{' '}
          <a
            href="https://joms.in/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="https://joms.in/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Privacy Policy
          </a>.
        </p>
      </section>

      <section className={styles.section}>
        <h2>APPLICATION AND REGISTRATION PROCESS</h2>
        <p>
          All applications are reviewed carefully. Factors such as "real world" network relationships and engagement indicators are considered.
        </p>
      </section>
    </div>
  )
}

export default PrivacyPolicy