import React from 'react'
import styles from '../../styles/components/internship/WhyWithUs.module.css';

const WhyWithUs = () => {
  const benefits = [
    {
      icon: '/assets/images/internship/wiwu-icon1.png',
      text: 'Students will get an Internship experience certificate for successfully completing the program as per policy.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon2.png',
      text: 'On-site, Hybrid, Remote working option to accommodate your academic commitments.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon3.png',
      text: 'Full-time role offer based on your performance and company\'s requirements.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon4.png',
      text: 'Trainings and Workshops are held periodically for up-skilling.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon5.png',
      text: 'Join us at industry events, with all costs covered for you to gain valuable experience.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon6.png',
      text: 'Travel Reimbursement related to internship activities based on your role.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon7.png',
      text: 'Free Food and Beverages while working at the office, any time of the day.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon8.png',
      text: 'Leave Policy to cater to any health or life situations, upon approval.'
    },
    {
      icon: '/assets/images/internship/wiwu-icon9.png',
      text: 'Public Holidays in accordance with company policy.'
    }
  ];

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Why Intern With Us?</h2>
      <div className={styles.grid}>
        {benefits.map((benefit, index) => (
          <div key={index} className={styles.card}>
            <img src={benefit.icon} alt="icon" className={styles.icon} />
            <p className={styles.text}>{benefit.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyWithUs