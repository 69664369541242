import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/home/Hero.module.css';
import { FiMenu, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <section className={styles.hero}>
      <nav className={styles.navbar}>
        <div className={styles.logoContainer}>
          {/* <img className={styles.logo} src='/assets/images/home/logo.png' alt="JOMS" /> */} <div className={styles.logo}>JOMS</div>
        </div>

        {!isMobile && (
          <ul className={styles.navLinks}>
            <li><a href="/careers">Careers</a></li>
            <li><a href="#products">Our Products</a></li>
          </ul>
        )}

        {!isMobile && (
          <button className={styles.joinButton} onClick={() => navigate('/join-us')}>
            Join Us
          </button>
        )}

        {/* Burger Menu */}
        {isMobile && <div className={`${styles.burgerMenu} ${menuOpen ? styles.burgerOpen : ''}`} onClick={toggleMenu}>
          {menuOpen ? <FiX size={30} color="#fff" /> : <FiMenu size={30} color="#fff" />}
        </div> }
      </nav>

      {/* Mobile Menu */}
      <div className={`${styles.mobileNav} ${menuOpen ? styles.showMenu : ''}`}>
        <ul>
          <li onClick={toggleMenu}><a href="/careers">Careers</a></li>
          <li onClick={toggleMenu}><a href="#products">Our Products</a></li>
          <li>
            <button className={styles.joinButton} onClick={() => navigate('/join-us')}>
              Join Us
            </button>
          </li>
        </ul>
      </div>

      {/* Hero Content */}
      <div className={styles.content}>
        <h1 className={styles.title}>JOMS</h1>
        <h2 className={styles.subtitle}>Just One More Step</h2>
        <p className={styles.tagline}>INNOVATE TO ELEVATE</p>
      </div>
    </section>
  );
};

export default Hero;
