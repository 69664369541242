import React, { useEffect } from 'react';
import styles from '../styles/pages/StoryDetails.module.css';
import { FaArrowLeft } from 'react-icons/fa';

const StoryDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={() => window.history.back()}>
          <FaArrowLeft className={styles.icon} />
        </button>
        <h1 className={styles.title}>A Journey of Resilience and Determination</h1>
      </div>
      <img
        src="/assets/images/story-details.png" // Replace with the actual image source
        alt="Sprout growing from soil"
        className={styles.image}
      />
      <div className={styles.content}>
        <p className={styles.paragraph} style={{ textIndent: '4rem' }}><strong>JOMS</strong> was born out of a simple yet profound phrase: <strong>Just One More Step.</strong> It’s a mantra that became my lifeline during moments of doubt and exhaustion. My childhood friend often said it to me when the weight of uncertainty felt too heavy to bear, reminding me to keep going, even when the path ahead seemed impossible. Those words weren’t just encouragement, they were a philosophy that carried me through one of the most transformative chapters of my life.</p>
        <p className={styles.paragraph}>Leaving a high-flying corporate job to pursue a lifelong dream of building something meaningful wasn’t just a leap of faith; it was a free fall into the unknown. I had always imagined entrepreneurship as an exciting adventure, but I quickly learned it was more about resilience than ambition. Each day felt like climbing a steep hill, only to face another just as challenging. Sleepless nights became the norm, filled with anxiety, self-doubt, and the haunting question: Should I just quit and go back to corporate world?</p>
        <p className={styles.paragraph}>The struggles didn’t stop at the professional front. Balancing personal responsibilities with the demands of starting a business felt like walking a tightrope. Every stumble reminded me of why I started and as if that weren’t enough, I faced criticism from people I had thought would be my strongest supporters. Their doubts cut deeper than I anticipated, leaving me to question not just my decisions but my very ability to succeed. In those moments, it felt like the world was closing in, with no safety net in sight.</p>
        <p className={styles.paragraph}>But even in the darkest times, one thing kept me going: the vision of creating something that truly mattered, something that could make a difference in people’s lives. It wasn’t just about building a business; it was about building a long lasting purpose.</p>
        <p className={styles.paragraph}>Soon I wasn’t alone in this journey. My childhood friend joined hands as she shared the same unrelenting fire for creating impact. Together, we founded <strong>JOMS Commerce and Technologies Pvt. Ltd.</strong>, not just as a business but as a testament to the power of persistence. What started as one person chasing his dreams has grown into a passionate team of individuals who believe in the transformative power of innovation and determination.</p>
        <p className={styles.paragraph}><strong>JOMS isn’t just a company;</strong> it’s a reflection of our journey, of every doubt silenced by just one more step, of every failure turned into a lesson, and of every barrier broken by sheer will. We are building products that don’t just solve problems but inspire change, reaching beyond borders to touch lives and challenge conventions.</p>
        <p className={styles.paragraph}>Today, when I look back at those sleepless nights and uphill battles, I see them as the foundation of something extraordinary. JOMS is not just our story; it’s the story of anyone who dares to dream and has the courage to take just one more step toward making it a reality. For those who’ve ever felt like giving up, know this: resilience isn’t just enduring, it’s transforming adversity into purpose.</p>
        <p className={styles.paragraph} style={{ fontStyle: 'italic' }}><strong>JOMS</strong> exists to celebrate that journey. And we’re just getting started...</p>
      </div>
    </div>
  );
};

export default StoryDetails;