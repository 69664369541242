import React from 'react'
import Hero from '../components/careers/Hero'
import AboutUs from '../components/careers/AboutUs'
import LifeAtJOMS from '../components/careers/LifeAtJoms'
import PerksAndBenefits from '../components/careers/PerksAndBenefits'
import TeamsAtJoms from '../components/careers/TeamsAtJoms'
import InternshipAtJoms from '../components/careers/InternshipAtJoms'
import CurrentOpenings from '../components/careers/CurrentOpenings'
import JobSubscription from '../components/careers/JobSubscription'

const Careers = () => {
  return (
    <>
        <Hero />
        <AboutUs />
        <LifeAtJOMS />
        <PerksAndBenefits />
        <TeamsAtJoms />
        <InternshipAtJoms />
        <CurrentOpenings />
        <JobSubscription />
    </>
  )
}

export default Careers