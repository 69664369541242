import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../styles/components/careers/JobSubscription.module.css";
import { API_BASE_URL } from "../../utils/constants";

const JobSubscription = () => {
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [loading, setLoading] = useState(false);
  // const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form data
    if (!formData.name || !formData.email) {
      toast.error("Please fill out all fields.");
      return;
    }

    // Set loading state while waiting for response
    setLoading(true);

    try {
      // Submit form data to the backend using Axios
      const response = await axios.post(`${API_BASE_URL}/api/joms-careers-subscription/subscribe`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        toast.success("Thank you for subscribing!");
        // setSubmitted(true);
        setFormData({ name: "", email: "" });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    <div id="job-subscription" className={styles.container}>
      <h2 className={styles.heading}>
        Leave your email to receive updates about openings & careers at JOMS
      </h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          className={styles.input}
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          className={styles.input}
        />
        <button type="submit" className={styles.button} disabled={loading}>
          {loading ? "Submitting..." : "Send"}
        </button>
      </form>
      {/* {submitted && <p className={styles.successMessage}>Thank you for subscribing!</p>} */}
    </div>
    </>
  );
};

export default JobSubscription;
