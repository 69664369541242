import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Preloader from "./components/home/Preloader";
import Home from "./pages/Home";
import Openings from "./pages/Openings";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import JobDetails from "./pages/JobDetails";
import JoinUs from "./pages/JoinUs";
import StoryDetails from "./pages/StoryDetails";
// import ProductDetails from "./pages/ProductDetails";
import Careers from "./pages/Careers";
import Internship from "./pages/Internship";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = () => {
      setTimeout(() => setLoading(false), 500);
    };

    loadData();
  }, []);

  // useEffect(() => {
  //   const pointerDot = document.querySelector(`.${styles.pointerDot}`);

  //   const handleMouseMove = (e) => {
  //     const x = e.clientX;
  //     const y = e.clientY;
  //     pointerDot.style.left = `${x}px`;
  //     pointerDot.style.top = `${y}px`;
  //   };

  //   document.addEventListener('mousemove', handleMouseMove);

  //   return () => {
  //     document.removeEventListener('mousemove', handleMouseMove);
  //   };
  // }, []);

  return (
    <Router>
      <div>
        {loading ? (
          <Preloader />
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/join-us" element={<JoinUs />} />
              <Route path="/joms-story" element={<StoryDetails />} />
              {/* <Route path="/joms-products" element={<ProductDetails />} /> */}
              <Route path="/careers/openings" element={<Openings />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/careers/openings/job-details/:id" element={<JobDetails />} />
              <Route path="/careers/internship-at-joms" element={<Internship />} />
            </Routes>
          </>
        )}
        {/* <div className={styles.pointerDot}></div> */}
      </div>
    </Router>
  );
};

export default App;
