import React from 'react'
import styles from '../../styles/components/internship/WhyJOMS.module.css';

const WhyJOMS = () => {
    return (
      <section className={styles.container}>
        <h2 className={styles.heading}>Why JOMS ?</h2>
        <p className={styles.description}>
          Embark on a learning journey with JOMS, where you will work on groundbreaking projects
          that create real-world impact while collaborating with people in a dynamic, growth-driven
          environment. This is your chance to turn passion into purpose, gain hands-on experience with
          cutting-edge technologies, and build a strong foundation for a remarkable career.
        </p>
      </section>
    );
  };

export default WhyJOMS