import React from 'react'
import styles from '../../styles/components/internship/Hero.module.css'
import { useNavigate } from 'react-router-dom';

const Hero = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.heroContainer}>
            <div className={styles.contentWrapper}>
                <div className={styles.textContent}>
                    <h1 className={styles.title}>Internship At</h1>
                    <h1 className={styles.brand}>JOMS</h1>
                    <button className={styles.button} onClick={() => navigate('/careers/openings')}>Explore Internships</button>
                </div>
            </div>
            <div className={styles.imageWrapper}>
                <img
                    src="/assets/images/internship/hero.png"
                    alt="Interns"
                    className={styles.image}
                />
            </div>
        </div>
    );
};

export default Hero;
