import React from "react";
import styles from "../../styles/components/careers/Hero.module.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const handleCheckOpenings = () => {
    navigate("/careers"); // Navigate to the Careers page
    setTimeout(() => {
      const section = document.getElementById("current-openings");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Wait for navigation to complete
  };
  return (
    <section className={styles.hero}>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h1 className={styles.heading}>
            We Made Of People <br/> Who can Make a <span className={styles.highlight}>Difference</span>
          </h1>
          <button className={styles.button} onClick={handleCheckOpenings}>Check Openings</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;