import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import styles from '../styles/pages/JobDetails.module.css';
import { FaRegCalendarAlt, FaSuitcase } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import Popup from '../components/careers/Popup';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const JobDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isSuccess) {
      console.log("Success state changed to:", isSuccess);
  
      const toastTimeout = setTimeout(() => {
        toast.success('Application submitted successfully!');
        console.log("Toast displayed, isSuccess:", isSuccess);
      }, 1000);
  
      const resetTimeout = setTimeout(() => {
        setIsSuccess(false);
        console.log("Resetting isSuccess to false");
      }, 4000); 
  
      return () => {
        clearTimeout(toastTimeout);
        clearTimeout(resetTimeout);
      };
    }
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { state } = location || {};
  const job = state?.job || {
    title: 'Unknown Job',
    details: {
      tags: ['N/A'],
      location: 'N/A',
      jobType: 'N/A',
      salary: 'N/A',
      url: ''
    },
    description: {
      overview: {
        jobTitle: 'N/A',
        mode: 'N/A',
        employmentType: 'N/A',
        duration: 'N/A'
      },
      aboutUs: 'No information available.',
      roleOverview: 'No information available.',
      keyResponsibilities: ['No responsibilities listed.'],
      qualifications: ['No qualifications listed.'],
      perksAndBenefits: ['No perks listed.'],
      howToApply: 'No application instructions available.'
    }
  };

  return (
    <>
      <ToastContainer style={{zIndex: 3000}} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className={styles.container}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack /> <span>Careers</span>
        </button>
        <div className={styles.cover}></div>

        <div className={styles.logoContainer}>
          <div className={styles.logo}></div>
          <h3 className={styles.logoText}>JOMS</h3>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.header}>
            <h2 className={styles.title}>{job.title}</h2>
            <div className={styles.details}>
              <span className={styles.detailItem}><FaSuitcase /> {job.details.type}</span>
              <span className={styles.detailItem}><FiMapPin /> {job.details.mode}</span>
              <span className={styles.detailItem}><FaRegCalendarAlt /> {job.details.duration}</span>
            </div>
          </div>
          <div className={styles.applyButtonContainer}>
            <button className={styles.applyButton} onClick={handleToggle}>Apply Now</button>
          </div>
        </div>

        <div className={styles.description}>
          <h3>About Us</h3>
          <p>{job.description.aboutUs}</p>

          <h3>Role Overview</h3>
          <p>{job.description.roleOverview}</p>

          <h3>Key Responsibilities</h3>
          <ul>
            {job.description.keyResponsibilities.map((responsibility, index) => (
              <li key={index}>{responsibility}</li>
            ))}
          </ul>

          <h3>Qualifications</h3>
          <ul>
            {job.description.qualifications.map((qualification, index) => (
              <li key={index}>{qualification}</li>
            ))}
          </ul>

          <h3>Perks and Benefits</h3>
          <ul>
            {job.description.perksAndBenefits.map((perk, index) => (
              <li key={index}>{perk}</li>
            ))}
          </ul>

          <h3>How to Apply</h3>
          <p>{job.description.howToApply}<span className={styles.mail}>hello@joms.in</span></p>
        </div>

        <Popup isOpen={isOpen} handleToggle={handleToggle} job={job} setIsSuccess={setIsSuccess} />
      </div>
    </>
  );
};

export default JobDetails;
