import React from 'react';
import styles from '../../styles/components/home/Team.module.css';
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const Team = () => {
  const navigate = useNavigate();
  
  const teamMembers = [
    { name: 'Swaroop', role: 'Founder & CEO', color: 'red', img: '/assets/images/avatars/swaroop.png' },
    { name: 'Bhavana', role: 'Operations Head', color: 'yellow', img: '/assets/images/avatars/bhavana.png' },
    { name: 'Kavya', role: 'Sales & Operations', color: 'maroon', img: '/assets/images/avatars/kavya.png' },
    { name: 'Ranjith', role: 'Cloud Engineer', color: 'pink', img: '/assets/images/avatars/ranjith.png' },
    { name: 'Rakesh', role: 'Data Engineer', color: 'purple', img: '/assets/images/avatars/rakesh.png' },
    { name: 'Sundeep', role: 'Full Stack Developer', color: 'darkred', img: '/assets/images/avatars/sundeep.png' },
    { name: 'Shahina', role: 'Full Stack Developer', color: 'violet', img: '/assets/images/avatars/shahina.png' },
    { name: 'Manoj', role: 'Full Stack Developer', color: 'lightgreen', img: '/assets/images/avatars/manoj.png' },
    { name: 'Mythri', role: 'Branding & Marketing', color: 'lavender', img: '/assets/images/avatars/mythri.png' },
    { name: 'Punith', role: 'All Things Finance', color: 'orange', img: '/assets/images/avatars/punith.png' },
    { name: 'Vishal', role: 'UI/UX Designer', color: 'blue', img: '/assets/images/avatars/vishal.png' },
  ];

  const handleClick = () => {
    navigate('/careers');
  };

  return (
    <div className={styles.teamContainer}>
      <h2 className={styles.title}>Meet The Team</h2>
      <div className={styles.grid}>
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className={styles.card}
            style={{ backgroundColor: member.color }}
          >
            <img src={member.img} className={styles.avatar} alt={member.name} />
            <div className={styles.infoWrapper}>
              <span className={styles.name}>{member.name}</span>
              <span className={styles.role}>{member.role}</span>
              <a href="https://linkedin.com" className={styles.linkedInIcon} aria-label="LinkedIn">
                <FaLinkedin />
              </a>
            </div>
          </div>
        ))}

        <div className={styles.emptyCard} onClick={handleClick}>
          <p>Want to make this 
          10/10 ?</p> <strong>Click here</strong>
        </div>
      </div>
    </div>
  );
};

export default Team;
