import React from 'react';
import styles from '../../styles/components/internship/CareerExplorer.module.css';

const CareerExplorer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <img
            src="/assets/images/internship/career-explorer1.avif"
            alt="Career discussion"
            className={styles.image}
          />
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.heading}>
            Looking to discover, launch, or accelerate your career?
          </h2>
          <ul className={styles.features}>
            <li className={styles.featureItem}>
              <img
                src="/assets/images/internship/ce-icon1.png"
                className={styles.icon}
                alt="Focus icon"
              />
              <div>
                <h3 className={styles.featureTitle}>Focus on building your career</h3>
                <p className={styles.featureDescription}>
                  3-6 months, aligned with the company’s needs and adaptable to college or university internship guidelines.
                </p>
              </div>
            </li>
            <li className={styles.featureItem}>
              <img
                src="/assets/images/internship/ce-icon2.png"
                className={styles.icon}
                alt="Time commitment icon"
              />
              <div>
                <h3 className={styles.featureTitle}>Time commitment</h3>
                <p className={styles.featureDescription}>
                  Interns should commit up to 20 hours per week for a full-time internship or at least 15 hours weekly if academic commitments arise.
                </p>
              </div>
            </li>
            <li className={styles.featureItem}>
              <img
                src="/assets/images/internship/ce-icon3.png"
                className={styles.icon}
                alt="Business hours icon"
              />
              <div>
                <h3 className={styles.featureTitle}>Business Hours</h3>
                <p className={styles.featureDescription}>
                  10 AM - 6 PM, Monday to Friday.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CareerExplorer;
