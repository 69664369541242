import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ClipLoader } from 'react-spinners';
import styles from '../../styles/components/home/Preloader.module.css';

const Preloader = () => {
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    if (loadingComplete) {
      const timeout = setTimeout(() => {
        setLoadingComplete(false);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [loadingComplete]);

  return (
    <motion.div
      className={styles.preloader}
      initial={{ opacity: 1 }}
      animate={loadingComplete ? { opacity: 0 } : { opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      {!loadingComplete && (
        <div className={styles.content}>
          <ClipLoader color="#ff6f91" size={40} />
        </div>
      )}
    </motion.div>
  );
};

export default Preloader;