import React from "react";
import { FaEye } from "react-icons/fa";
import { BiTargetLock } from "react-icons/bi";
import styles from "../../styles/components/home/AboutUs.module.css";

const Aboutus = () => {
  return (
    <div className={styles.container}>
      <section className={styles.aboutSection}>
        <h2 className={styles.heading}>About Us</h2>
        <p className={styles.description1}>
          JOMS, short for <strong style={{ color: "#000" }}>Just One More Step</strong>, combines
          creativity and technology to develop innovative products that elevate
          customer experiences.
        </p>
        <p className={styles.description2}>
          With a focus on technology as a core enabler, we design and deliver
          premium products and solutions across diverse industries, including
          e-commerce, fashion, food, dating, and networking. Our mission is to
          create meaningful impact and lasting value by redefining how customers
          connect with the products and services they love.
        </p>
      </section>
      <div className={styles.visionMissionContainer}>
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <div className={styles.iconCircle}>
              <FaEye className={styles.icon} />
            </div>
          </div>
          <h3 className={styles.cardHeading}>Vision</h3>
          <p className={styles.cardDescription}>
            Inspire global change by creating a better world with innovative
            products and services that elevate the human race.
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <div className={styles.iconCircle}>
              <BiTargetLock className={styles.icon} />
            </div>
          </div>
          <h3 className={styles.cardHeading}>Mission</h3>
          <p className={styles.cardDescription}>
            Elevate social experiences by blending passion, creativity, and
            technology to deliver practical solutions that drive innovation and
            foster growth.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
