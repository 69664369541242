import React from "react";
import styles from "../../styles/components/careers/PerksAndBenefits.module.css";

const PerksAndBenefits = () => {
  return (
    <div className={styles.container}>
      <div className={styles.perksHeader}>Our Perks & Benefits</div>
      <div className={styles.perksContent}>
        <div className={styles.imageSection}>
          <img
            src="/assets/images/careers/perks.jpeg"
            alt="Team meeting"
            className={styles.image}
          />
        </div>
        <div className={styles.benefitsSection}>
          <div className={styles.benefitItem}>
            <img src="/assets/images/careers/perks_1.png" alt="perks" className={styles.icon}/>
            <div className={styles.text}>Collaborative Culture</div>
          </div>
          <div className={styles.benefitItem}>
            <img src="/assets/images/careers/perks_2.png" alt="perks" className={styles.icon}/>
            <div className={styles.text}>Flexible Work Option</div>
          </div>
          <div className={styles.benefitItem}>
            <img src="/assets/images/careers/perks_3.png" alt="perks" className={styles.icon}/>
            <div className={styles.text}>Continuous Learning</div>
          </div>
          <div className={styles.benefitItem}>
            <img src="/assets/images/careers/perks_4.png" alt="perks" className={styles.icon}/>
            <div className={styles.text}>Health Programs</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerksAndBenefits;