import React from 'react';
import styles from '../../styles/components/home/Products.module.css';
// import { useNavigate } from 'react-router-dom';

const Products = () => {
  // const navigate = useNavigate();

  return (
    <div id="products" className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>A Glimpse of Our Product</h2>
        <img src="/assets/images/home/launching-soon.jpeg" alt="Product" className={styles.image} />
        {/* <p className={styles.description}>
          Connect, network, and thrive in an exclusive ecosystem designed for high-profile individuals. Discover VIP events, curated experiences, and access to luxury, elevating your lifestyle and fostering meaningful connections.
        </p>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <img
              src="/assets/images/home/product1.png"
              alt="Meet Your Match"
              className={styles.image}
            />
            <p className={styles.cardTitle}>Meet Your Match</p>
          </div>
          <div className={styles.card}>
            <img
              src="/assets/images/home/product2.png"
              alt="Prestige Events"
              className={styles.image}
            />
            <p className={styles.cardTitle}>Prestige Events</p>
          </div>
          <div className={styles.card}>
            <img
              src="/assets/images/home/product3.png"
              alt="Social Ties"
              className={styles.image}
            />
            <p className={styles.cardTitle}>Social Ties</p>
          </div>
        </div>
        <button className={styles.exploreButton} onClick={() => navigate('/joms-products')}>Explore</button> */}
      </div>
    </div>
  );
};

export default Products;
