import React from 'react'
import styles from '../../styles/components/internship/HowToApply.module.css';

const HowToApply = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>How To Apply ?</h2>
            <p className={styles.description}>
                If you're passionate about innovation and are looking to make a meaningful impact, we'd love to hear from you. To apply, students must submit their updated resume or curriculum vitae (CV) along with their work portfolio document or link, if any. Visit our <a href="/careers/openings" className={styles.link}>Careers Page</a> to view current openings and submit your application. Once shortlisted, we will notify you through a phone call or email. Stay tuned!
            </p>
        </div>
    );
};

export default HowToApply