import React from "react";
import styles from "../../styles/components/careers/LifeAtJoms.module.css";

const LifeAtJoms = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.heading}>Life at JOMS</h2>
      </div>
      <div className={styles.quoteBox}>
          <img
            src="/assets/images/careers/quoteopen.png"
            className={styles.quoteMarkOpen}
            alt="quote mark"
          />
        <p className={styles.quote}>
          At JOMS, we believe that a supportive and inclusive  work environment
          is key to fostering innovation & growth. 
        </p>
          <img
            src="/assets/images/careers/quoteclose.png"
            className={styles.quoteMarkClose}
            alt="quote mark"
          />
      </div>
    </div>
  );
};

export default LifeAtJoms;