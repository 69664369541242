import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../styles/components/careers/Popup.module.css";
import { MdClose } from "react-icons/md";
import { API_BASE_URL } from "../../utils/constants";
console.log("API_BASE_URL:", API_BASE_URL);
const Popup = ({ isOpen, handleToggle, job, setIsSuccess }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneCode: "+91",
    phoneNumber: "",
    email: "",
    portfolioLink: "",
    file: null,
    role: job.role,
    jobid: job.id,
  });

  const [errors, setErrors] = useState({});
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (errors[name]) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      file,
    }));

    if (file) {
      const newErrors = { ...errors };
      delete newErrors.file;
      setErrors(newErrors);
      setUploadedFileName(file.name);
    }
  };

  const clearFileUpload = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: null,
    }));
    setUploadedFileName("");
  };

  const closeButton = () => {
    handleToggle();
    setFormData({
      firstName: "",
      lastName: "",
      phoneCode: "+91",
      phoneNumber: "",
      email: "",
      portfolioLink: "",
      file: null,
      role: job.title,
      jobid: job.id,
    });
    setUploadedFileName("");
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    if (!/^[+]?\d{7,15}$/.test(formData.phoneNumber))
      newErrors.phoneNumber = "Enter a valid Phone Number";
    if (!formData.email) newErrors.email = "Email is required";
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email))
      newErrors.email = "Enter a valid email address";
    if (!formData.file) newErrors.file = "CV is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error("Please fix the errors before submitting.");
    } else {
      setErrors({});
      setIsSubmitting(true);

      const formDataToSend = new FormData();
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("phoneCode", formData.phoneCode);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("portfolioLink", formData.portfolioLink);
      formDataToSend.append("file", formData.file);
      formDataToSend.append("role", formData.role);
      formDataToSend.append("jobid", formData.jobid);

      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/careers/apply`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Response:", response.data);
        if (response.data.success) {
          setIsSuccess(true);
          handleToggle();
          setFormData({
            firstName: "",
            lastName: "",
            phoneCode: "+91",
            phoneNumber: "",
            email: "",
            portfolioLink: "",
            file: null,
            role: job.role,
            jobid: job.id,
          });
          clearFileUpload();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || "Something went wrong.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className={styles.overlay}>

        <div className={styles.popup}>
          <button className={styles.closeButton} onClick={closeButton}>
            <MdClose />
          </button>
          <div className={styles.header}>
            <div className={styles.logo}></div>
            <div className={styles.headingWrapper}>
              <h2 className={styles.title}>{job.title}</h2>
              <p className={styles.subtitle}>
                Joms commerce & Technology Pvt.Ltd
              </p>
            </div>
          </div>
          <div className={styles.body}>
            <h3 className={styles.sectionTitle}>Basic Details</h3>
            <div className={styles.fieldGroup}>
              <div className={styles.inputWrapper}>
                <label className={styles.label}>First Name *</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  className={`${styles.input} ${errors.firstName ? styles.error : ""
                    }`}
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.inputWrapper}>
                <label className={styles.label}>Last Name *</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  className={`${styles.input} ${errors.lastName ? styles.error : ""
                    }`}
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <div className={styles.inputWrapper}>
                <label className={styles.label}>Phone Number *</label>
                <div className={styles.phoneInputWrapper}>
                  <select
                    className={styles.countryCode}
                    name="phoneCode"
                    value={formData.phoneCode}
                    onChange={handleInputChange}
                  >
                    <option value="+91">+91</option>
                    <option value="+1">+1</option>
                    <option value="+44">+44</option>
                    <option value="+61">+61</option>
                  </select>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    className={`${styles.phoneInput} ${errors.phoneNumber ? styles.error : ""
                      }`}
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles.inputWrapper}>
                <label className={styles.label}>Email *</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  className={`${styles.input} ${errors.email ? styles.error : ""
                    }`}
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <h3 className={styles.sectionTitle}>Documents</h3>
            <div className={styles.inputWrapper}>
              <label className={styles.label}>Portfolio Link</label>
              <input
                type="text"
                name="portfolioLink"
                placeholder="https://example.com"
                className={styles.inputFull}
                value={formData.portfolioLink}
                onChange={handleInputChange}
              />
            </div>
            <label className={styles.label}>Resume</label>
            {uploadedFileName && (
              <div className={styles.fileNameBox}>
                <span>{uploadedFileName}</span>
                <MdClose
                  className={styles.clearFileIcon}
                  onClick={clearFileUpload}
                />
              </div>
            )}
            <label
              className={`${styles.uploadLabel} ${errors.file ? styles.error : ""
                }`}
            >
              <input
                type="file"
                name="file"
                className={styles.fileInput}
                onChange={handleFileUpload}
              />
              <span>Upload CV *</span>
            </label>
            <button
              className={styles.submitButton}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
